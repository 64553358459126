<nav id="navbar-example2" class="navbar navbar-light bg-light">
    <a class="navbar-brand" routerLink="/mainComponent"><i class="fa fa-home home" aria-hidden="true"></i></a>
    <span class="pagetitle" *ngIf="!unAuthLogin">CLASS LICENSING REGIME</span>
    <!-- <span class="pagetitle">CUBS - CLASS LICENSING REGIME</span> -->
    
    <ul class="nav nav-pills" *ngIf="!unAuthLogin">
      <li *ngIf="showHome">
        <a class="nav-link icon" href="javascript:void(0)" (click)="homePage()">
          <i class="fa fa-home" aria-hidden="true"></i>
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link icon" href="javascript:void(0)" (click)="toggle($event)">
          <i class="fa fa-user" aria-hidden="true"></i>
        </a>
        <div class="dropdownbox" *ngIf="expanded && !unAuthLogin">
          <ul (click)="$event.stopPropagation()">
             <li class="item-text" (click)="changePassword()">Change Password</li>
             <li class="item-text" (click)="Signout()">Logout</li>
          </ul>
      </div>
      </li>
    </ul>
  </nav>
  <div *ngIf="ErrorsPopupModelDispay" class="custom-model">
    <div class="model">
        <div class="model-body">
            <div class="modal-header">
                <h5 class="modal-title">Session Expired</h5>
            </div>
            <p class="promptMsg">{{promptmessage}}</p>
            <div class="buttonclass"><button class="buttonprimary" (click)="closepopup()">OK</button>&nbsp;&nbsp;&nbsp;</div>
        </div>
    </div>
    <div class="model-background"></div>
</div>