
<div class="container-fluid">
    <div class="row topspace1">
        <div class="col-12">
            <h5>Strike Data Upload</h5>
        </div>
    </div>
    <div class="row topspace">
        <div class="col-12">
            <div class="file-container"
            (dragover)="onDragOver($event)"
            (drop)="onDrop($event)"
            (dragleave)="onDragLeave($event)"
            [class.activeclass]="isActive">
                <input type="file" #csvReader name="uploadfile" id="myFile" class="browse-file" (change)="onSelectedFile($event)" />
                <!-- <input type="file" name="userfiles" accept=".csv,text/csv" class="browse-file" (change)="onSelectedFile($event)" /> -->
                <i class="fa fa-download" aria-hidden="true"></i>
                <h3>Drag and drop file here</h3>
                <h3>or</h3>
                <label for="fileDropRef" class="btn btn btn-success">BROWSE FOR FILE</label>
            </div>
        <div class="files-list" *ngFor="let filaname of droppedFile">
                <div class="single-file">
                    <div class="file-icon">
                        <i class="fa fa-file-excel-o xls" aria-hidden="true"></i>
                     
                    </div>
                    <div class="info">
                         <h4 class="name">
                            {{filaname.name}}
                        </h4>
                        <!-- <p class="size">
                            {{ formatBytes(file?.size) }}
                        </p> -->
                        <!-- <div class="mt-2" *ngIf="upload.progress > 0 && upload.progress != 100">
                            <div class="progress" style="height: 20px;margin-bottom:20px;">
                              <div class="progress-bar progress-bar-striped bg-info" role="progressbar" [style.width.%]="upload.progress" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{upload.progress}}%</div>
                            </div>
                        </div> -->
                    </div>
            
                    <div class="delete" (click)="deleteFile()">
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row topspace1 btmspace1">
        <div class="col-12 text-center dataupload">
            <button (click)="uploadFile()" [ngClass]="!droppedFile ? 'btn-secondary' : 'btn-success'" class="btn"  [disabled]="!droppedFile"><i class="fa fa-plus" aria-hidden="true"></i> UPLOAD</button>
            <span class="btnspac">&nbsp;</span>
            <button (click)="onPress()" class="btn btn btn-success">FILE HISTORY</button>
        </div>
    </div>
</div>


<div *ngIf="errorMessage" class="custom-model">
    <div class="model">
        <div class="model-body">
            <p>
                <i class="fa fa-times-circle-o" *ngIf="!isValidFile" aria-hidden="true"></i>
                <i class="fa fa-check-circle-o" *ngIf="isValidFile" aria-hidden="true"></i>
            </p>
            <!-- <i class="fa fa-times-circle-o" aria-hidden="true"></i> -->
            <p>{{errorMessage}}</p>
            <p>{{errorMessage1}}</p>
            <p>{{successs}}</p>
            <p class="btnspc"><button (click)="closePopup($event)" class="btn btn btn-success">OK</button></p>
        </div>
    </div>
    <div class="model-background"></div>
</div>
<div *ngIf="inprogress" class="custom-model">
    <div class="model">
        <div class="model-body">
            <p>
                <i class="fa fa-circle-o-notch" aria-hidden="true"></i>
            </p>
            <p>Uploading Inprogress...</p>
        </div>
    </div>
    <div class="model-background"></div>
</div>
<div class="topspace1" *ngIf="display">
        <app-file-history></app-file-history>
</div>