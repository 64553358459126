import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class FileApprovalService {

  //public baseUrl = 'https://zi3mv8ef49.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-approver';
 //public baseUrl = 'https://cubs-uat-api.dslmonitoring.net/strike-approval/approval';
  public baseUrl = 'https://cubs-api.dslmonitoring.net/strike-approval/approval';

  //public insUrl = 'https://wpvppebfi2.execute-api.ap-southeast-1.amazonaws.com/dev/insert-data';
   // public insUrl = 'https://cubs-uat-api.dslmonitoring.net/insert-data/insertdata';
  public insUrl = 'https://cubs-api.dslmonitoring.net/insert-data/insertdata';

  //public DownloadbaseUrl = 'https://h0wdq67ysk.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-class-licensing-regime-dev-file-upload?file=';   //dev
    // public DownloadbaseUrl = 'https://cubs-uat-api.dslmonitoring.net/download-file/cubs-class-licensing-regime-test-file-upload?file=';
  public DownloadbaseUrl = 'https://cubs-api.dslmonitoring.net/download-file/cubs-class-licensing-regime-prod-file-upload?file=';

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private cookieService: CookieService) { }
  updateFiles(obj: any): Observable<any> {
    let API_URL = `${this.baseUrl}`;
   //return this.http.post(API_URL, (obj))
    return this.http.post(API_URL, obj, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true })
      .pipe(
        catchError(this.error)
      )
  }

  insertRecords(obj: any): Observable<any> {
    let API_URL = `${this.insUrl}`;
    //return this.http.post(API_URL, (obj))
    return this.http.post(API_URL, obj, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true })
      .pipe(
        catchError(this.error)
      )
  }
  S3downloadFile(obj: string, uploadeddate: string) {
    let API_DOWNLOAD_URL = `${this.DownloadbaseUrl}`;
    console.log(API_DOWNLOAD_URL);
    uploadeddate = uploadeddate.substring(0, 11);
    // let params1= new HttpParams().set('file',obj);
    //return this.http.get<any>(API_DOWNLOAD_URL + obj + '&uploadeddate=' + uploadeddate);
    return this.http.get<any>(API_DOWNLOAD_URL + obj + '&uploadeddate=' + uploadeddate, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
  }



  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
