import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders, HttpErrorResponse, HttpHeaderResponse } from '@angular/common/http';
import { Observable, Operator } from 'rxjs';
import { Operators } from '../Operators';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  //private baseUrl = 'https://kxhe0xx515.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-opr-list';
  // private baseUrl = 'https://cubs-uat-api.dslmonitoring.net/get-opr-list/cubs-dsl-device-test-opr-list';
   private baseUrl = 'https://cubs-api.dslmonitoring.net/get-opr-list/cubs-dsl-device-prod-opr-list';

  //private OprByIdUrl = 'https://vzwncrbuyj.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-opr-details-byid';
  // private OprByIdUrl = 'https://cubs-uat-api.dslmonitoring.net/opr-detl/cubs-dsl-device-test-opr-details-byid';
  private OprByIdUrl = 'https://cubs-api.dslmonitoring.net/opr-detl/cubs-dsl-device-prod-opr-details-byid';

  //private LoginUrl = "https://c33ohaxjtl.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-clr-device-dev-user-login";
  //private LoginUrl = "https://idgz9koaoh.execute-api.ap-southeast-1.amazonaws.com/UAT/cubs-clr-device-test-user-login";

  //private OprUpdatebaseUrl = 'https://8ibt5d79jh.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-update-opr-list/';
  // private OprUpdatebaseUrl = 'https://cubs-uat-api.dslmonitoring.net/update-opr-list/cubs-dsl-device-test-update-opr-list/';
  private OprUpdatebaseUrl = 'https://cubs-api.dslmonitoring.net/update-opr-list/cubs-dsl-device-prod-update-opr-list/';

   // private sessionUrl = "https://test-api.dslmonitoring.net/auth/validate-session";
    // private sessionUrl = 'https://uat-api.dslmonitoring.net/auth/validate-session';
  private sessionUrl = 'https://api.dslmonitoring.net/auth/validate-session';

   // private tokenRenewalUrl = "https://test-api.dslmonitoring.net/auth/refresh-session";
   // private tokenRenewalUrl = "https://uat-api.dslmonitoring.net/auth/refresh-session";
  private tokenRenewalUrl = 'https://api.dslmonitoring.net/auth/refresh-session';

   // private logoutUrl = 'https://test-api.dslmonitoring.net/auth/logout';
     // private logoutUrl = 'https://uat-api.dslmonitoring.net/auth/logout';
  private logoutUrl = 'https://api.dslmonitoring.net/auth/logout';

  //private oprEmailNotificationURL = 'https://r7gdczluz4.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-opr-emailnotification/';
    // private oprEmailNotificationURL = 'https://cubs-uat-api.dslmonitoring.net/opr-email-notify/cubs-dsl-device-test-opr-emailnotification/';
  private oprEmailNotificationURL = 'https://cubs-api.dslmonitoring.net/opr-email-notify/cubs-dsl-device-prod-opr-emailnotification/';

  //private uploadFileEmailNotificationURL = 'https://r7gdczluz4.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-fileUpload-emailNotification/';
     // private uploadFileEmailNotificationURL = 'https://cubs-uat-api.dslmonitoring.net/opr-email-notify/cubs-dsl-device-test-fileupload-emailnotification/';
  private uploadFileEmailNotificationURL = 'https://cubs-api.dslmonitoring.net/opr-email-notify/cubs-dsl-device-prod-fileupload-emailnotification/';

  //private userBanData = 'https://zpnuuxzgi4.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-userbandata';
     // private userBanData = 'https://cubs-uat-api.dslmonitoring.net/userbansearch/cubs-dsl-device-test-userbandata';
  private userBanData = 'https://cubs-api.dslmonitoring.net/userbansearch/cubs-dsl-device-prod-userbandata';

  private auditBanDataURL = 'https://kl7tbriwga.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-auditbandata';

  constructor(private http: HttpClient, private cookieService: CookieService, private _router: Router) { }

  deploymentEnv: string = 'PROD'; // DEV || UAT || PROD ||
  dslmonitoringUatUrl: string = 'https://dslmonitoring.net:3438';
  dslmonitoringDevUrl: string = 'https://www.test-operators.dslmonitoring.net';
  dslmonitoringProdUrl: string = 'https://www.dslmonitoring.net';

  getDslMonitoringUrl(): any {
    if (this.deploymentEnv == 'DEV') {
      return this.dslmonitoringDevUrl;
    }
    else if (this.deploymentEnv == 'UAT') {
      return this.dslmonitoringUatUrl;
    }
    else if (this.deploymentEnv == 'PROD') {
      return this.dslmonitoringProdUrl;
    }
  }

  sendOprEmailNotification(oprDetails: any, operation: any) {
    return this.http.post<any>(this.oprEmailNotificationURL,
      {
        "params": oprDetails,
        "action": operation
        }, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
      // });
  }

  sendUploadFileEmailNotification(fileDetails: any, operation: any) {
    //console.log("details",fileDetails, operation)
    return this.http.post<any>(this.uploadFileEmailNotificationURL,
      {
        "params": fileDetails,
        "action": operation
        }, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
       // });
  }

  getOperators() {
    //return this.http.get<Operators[]>(this.baseUrl);
    return this.http.get<Operators[]>(this.baseUrl, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
  }

  getOperatorEdit(OperatorID: string): Observable<any> {
    let params1 = new HttpParams().set('OprID', OperatorID)
    //return this.http.get<Operators[]>(this.OprByIdUrl, { params: params1 });
    return this.http.get<Operators[]>(this.OprByIdUrl, { params: params1, headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
  }

  validateSession() {
    console.log("LOGIN SESSION")
    return this.http.get(this.sessionUrl, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
  }

  sessionTokenRenewal() {
    console.log("SESSION TOKEN RENEWAL")
    return this.http.get(this.tokenRenewalUrl, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
  }

  logout() {
    return this.http.get(this.logoutUrl, { withCredentials: true })
  }

  // ValidateAuthorization(login: any)
  // {
  //   console.log("LOGIN CREDS", login)
  //   let headers = new Headers({ 'Content-Type': 'application/json' });
  //   return this.http.post<any>(this.LoginUrl, 
  //     { "UserName": login.loginID,
  //     "Password" : login.password
  //   });
  // }
  getCurrentUser() {
    let currentUser = localStorage.getItem('currentUser') || '';
    if (currentUser.startsWith("CL-") || currentUser.startsWith("cl-")) {
      console.log("CUBS OPERATOR")
      this._router.navigate(['/userBanSearch']);
      return currentUser;
    } else {
      console.log("CUBS USER")
      this._router.navigate([this._router.url]);
      return currentUser;
    }
  }

  StringtoDate(datestring: string): any {
    //input   "20-4-2021" output: "2021-04-28"
    var first = datestring.indexOf("-");
    var last = datestring.lastIndexOf("-");
    var len = datestring.length;
    var d = datestring.substring(0, first);
    var m = datestring.substring(first + 1, last);
    var y = datestring.substring(last + 1, len);

    return y + "-" + m.padStart(2, '0') + "-" + d.padStart(2, '0');
  }

  AddOperator(opr: any) {
    let oprphonenumber = opr.OprPhoneNumberCode + ' ' + opr.OprPhoneNumber;
    let Rep1phonenumber = opr.Rep1PhoneNumberCode + ' ' + opr.Rep1PhoneNumber;
    let Rep2phonenumber = opr.Rep2PhoneNumberCode + ' ' + opr.Rep2PhoneNumber;

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.OprUpdatebaseUrl,
      {
        "OperatorID": opr.OperatorID,
        "UniqueEntityNumber": opr.UniqueEntityNumber,
        "OperatorShortName": opr.OperatorShortName,
        "OperatorLongName": opr.OperatorLongName,

        "OprContactPersonName": opr.OprContactPersonName,
        "OprRegisteredBusinessAddress": opr.OprRegisteredBusinessAddress,
        "OprSingaporeAddress": opr.OprSingaporeAddress,
        "OprPhoneNumber": oprphonenumber,
        "OprEmailAddress": opr.OprEmailAddress,

        "Rep1ContactPersonName": opr.Rep1ContactPersonName,
        "Rep1SingaporeAddress": opr.Rep1SingaporeAddress,
        //"Rep1PhoneNumber":opr.Rep1PhoneNumber,
        "Rep1PhoneNumber": Rep1phonenumber,
        "Rep1EmailAddress": opr.Rep1EmailAddress,

        "Rep2ContactPersonName": opr.Rep2ContactPersonName,
        "Rep2SingaporeAddress": opr.Rep2SingaporeAddress,
        //"Rep2PhoneNumber": opr.Rep2PhoneNumber,
        "Rep2PhoneNumber": Rep2phonenumber,
        "Rep2EmailAddress": opr.Rep2EmailAddress,

        "DeviceTypeClass1": opr.DeviceTypeClass1,
        "DeviceTypeClass2": opr.DeviceTypeClass2,
        "DeviceTypeClass3": opr.DeviceTypeClass3,
        //"NotificationDate":opr.NotificationDate,
        "NotificationDate": this.StringtoDate(opr.NotificationDate),
        //"OperatorEndDate":opr.OperatorEndDate,
        "OperatorEndDate": this.StringtoDate(opr.OperatorEndDate),
        "ComplianceDeclaration": opr.ComplianceDeclaration,
        "DeleteIndicator": '0',
        "AddIndicator": '1',
        "UserID": "system"
        }, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
       // });
  }

  UpdateOperator(opr: any) {
    let oprphonenumber = opr.OprPhoneNumberCode + ' ' + opr.OprPhoneNumber;
    let Rep1PhoneNumber = opr.Rep1PhoneNumberCode + ' ' + opr.Rep1PhoneNumber;
    let Rep2PhoneNumber = opr.Rep2PhoneNumberCode + ' ' + opr.Rep2PhoneNumber;

    let headers = new Headers({ 'Content-Type': 'application/json' });
    return this.http.post<any>(this.OprUpdatebaseUrl,
      {
        "OperatorID": opr.OperatorID,
        "UniqueEntityNumber": opr.UniqueEntityNumber,
        "OperatorShortName": opr.OperatorShortName,
        "OperatorLongName": opr.OperatorLongName,

        "OprContactPersonName": opr.OprContactPersonName,
        "OprRegisteredBusinessAddress": opr.OprRegisteredBusinessAddress,
        "OprSingaporeAddress": opr.OprSingaporeAddress,
        "OprPhoneNumber": oprphonenumber,
        "OprEmailAddress": opr.OprEmailAddress,

        "Rep1ContactPersonName": opr.Rep1ContactPersonName,
        "Rep1SingaporeAddress": opr.Rep1SingaporeAddress,
        //"Rep1PhoneNumber":opr.Rep1PhoneNumber,
        "Rep1PhoneNumber": Rep1PhoneNumber,
        "Rep1EmailAddress": opr.Rep1EmailAddress,

        "Rep2ContactPersonName": opr.Rep2ContactPersonName,
        "Rep2SingaporeAddress": opr.Rep2SingaporeAddress,
        //"Rep2PhoneNumber":opr.Rep2PhoneNumber,
        "Rep2PhoneNumber": Rep2PhoneNumber,
        "Rep2EmailAddress": opr.Rep2EmailAddress,

        "DeviceTypeClass1": opr.DeviceTypeClass1,
        "DeviceTypeClass2": opr.DeviceTypeClass2,
        "DeviceTypeClass3": opr.DeviceTypeClass3,
        //"NotificationDate":opr.NotificationDate,
        "NotificationDate": this.StringtoDate(opr.NotificationDate),
        //"OperatorEndDate":opr.OperatorEndDate,
        "OperatorEndDate": this.StringtoDate(opr.OperatorEndDate),
        "ComplianceDeclaration": opr.ComplianceDeclaration,
        "DeleteIndicator": '0',
        "AddIndicator": '0',
        "UserID": "system"
       }, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
       // })
  }

  DeleteOperator(OperatorID: string) {
    return this.http.post<any>(this.OprUpdatebaseUrl,
      {
        "OperatorID": OperatorID,
        "DeleteIndicator": '1',
        "AddIndicator": '0',
        "UserID": "system"
         }, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
      //})
  }

  PostData(name: string) {
    return this.http.post<any>(this.baseUrl, name, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true });
  }

  auditBan(ad: any) {
    return this.http.post<any>(`${this.auditBanDataURL}`, {
      "USER": ad.user,
      "USER_EMAILID": ad.userEmailId,
      "Phone_Number_Searched": ad.phoneNumberSearched,
      "Phone_No_Search_Result": ad.phoneNoSearchResult,
      "CREATED_DTTM": moment().tz("Asia/Singapore").format("DD-MMM-YYYY HH:mm:ss"),
    })
  }

  banSearch(searchData: any): Observable<any> {
    //return this.http.post<any>(`${this.userBanData}`, { "PhoneNumber": searchData })
    return this.http.post<any>(`${this.userBanData}`, { "PhoneNumber": searchData }, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true })
  }
}
