
<div class="container-fluid">
  <div class="row topspace1">
    <div class="col-12">
      <h5>Strike Data Approval</h5>
    </div>
  </div>
  <div *ngIf="fileHistory" class="topspace1">
    <div class="history-search" *ngIf="fileHistory.length > 5">
      <div class="row">
        <div class="maindivsearch">
          <i class="fa fa-search" aria-hidden="true"></i>
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off" class="right" placeholder="Search">
        </div>

      </div>
    </div>
    <!-- <div *ngIf="fileHistory.length == 0">
      <div class="row">
            <div class="col-12">
            <table style="text-align:center; width: 50%;">
                <tbody>
                  <tr >
                    <td style="text-align:center">Data not found</td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div> -->
    <div class="history" *ngIf="fileHistory.length > 0">
      <div class="row height-100">
        <div class="col-12"></div>
        <div class="col-12 tblcontentscroll height-100">
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col" (click)="sort('FILE_NAME')">File Name &nbsp; <i class="fa fa-sort"></i></th>
                <th scope="col" (click)="sort('STATUS')">Status &nbsp; <i class="fa fa-sort"></i></th>
                <th scope="col" (click)="sort('UPLOADED_BY')">Uploaded by <i class="fa fa-sort"></i></th>
                <th scope="col" (click)="sortByDate()">Uploaded Date &amp; Time &nbsp; <i class="fa fa-sort"></i></th>
                <th scope="col" (click)="sort('APPROVED_BY')">Approved by <i class="fa fa-sort"></i></th>
                <th scope="col" (click)="sort('STATUS')">Actions &nbsp; <i class="fa fa-sort"></i></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileHistory | filter:searchText | orderBy: key: reverse let i = index">

                <td>{{data.FILE_NAME}}</td>
                <td>
                  <span class="badge bg-success"
                    [ngClass]="{'bg-success': data.STATUS === 'Approved', 'bg-danger':data.STATUS === 'Rejected', 'bg-secondary':data.STATUS === 'Pending' }">{{data.STATUS}}</span>
                </td>
                <td>{{data.UPLOADED_BY}}</td>
                <td>{{data.UPLOADED_DTTM}}</td>
                <td>{{data.APPROVED_BY}}</td>
                <td>

                  <span *ngIf="data.STATUS === 'Pending'">
                    <a (click)="onSelect($event, data.FILE_NAME)">
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <i class="fa fa-circle" aria-hidden="true"></i>
                    </a>
                  </span>
                  <span *ngIf="data.STATUS != 'Pending'">
                    <a (click)="onSelect($event, data.FILE_NAME)">
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <i class="fa fa-circle" aria-hidden="true"></i>
                      <i class="fa fa-circle" aria-hidden="true"></i>
                    </a>
                  </span>
                  <div class="dropdownbox" *ngIf="showActiveDiv[data.FILE_NAME]">
                    <ul>
                      <li (click)="download($event, data, i)">Download</li>
                      <li *ngIf="data.STATUS === 'Pending'" (click)="approved($event, data, i)">Approve</li>
                      <li *ngIf="data.STATUS === 'Pending'"  (click)="rejected($event, data, i)">Reject</li>
                    </ul>
                  </div>

                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!fileHistory" class="errormsg">
    <span>{{errormsg}}</span>
  </div>
  <div *ngIf="showPopup" class="custom-model">
    <div class="model">
      <div class="model-body">
        <p>
          <i class="fa fa-times-circle-o" *ngIf="!approvedElement" aria-hidden="true"></i>
          <i class="fa fa-check-circle-o" *ngIf="approvedElement" aria-hidden="true"></i>
        </p>
        <p *ngIf="approvedElement">Approved Succussfully!</p>
        <p *ngIf="!approvedElement">Rejected Succussfully!</p>
        <p class="btnspc"><button (click)="closePopup($event)" class="btn btn btn-success">OK</button></p>
      </div>
    </div>
    <div class="model-background"></div>
  </div>
  <div *ngIf="!disableLink" class="custom-model">
    <div class="model">
      <div class="model-body">
        <p>
          <i class="fa fa-times-circle-o" aria-hidden="true"></i>
        </p>
        <p>You can not approve or reject the file uploaded by you</p>
        <p class="btnspc"><button (click)="closePopup($event)" class="btn btn btn-success">OK</button></p>
      </div>
    </div>
    <div class="model-background"></div>
  </div>
  <!-- <div *ngIf="onError" class="text-center">Enternal Server Error</div> -->
  <div *ngIf="spiner" class="text-center topspace">Loading...</div>
</div>



<div *ngIf="Downloadingspiner" class="custom-model">
  <div class="model">
    <div class="model-body">
      <p> <i class="fa fa-times-circle-o" *ngIf="!approvedElement" aria-hidden="true"></i> </p>
      <!-- <p>Downloading File...</p> -->

      <p>File Not Exists to Download!</p>
      <p class="btnspc"><button (click)="closePopup($event)" class="btn btn btn-success">OK</button></p>
    </div>
  </div>
  <div class="model-background"></div>
</div>

<div *ngIf="ErrorsPopupModelDispay" class="custom-model">
  <div class="model">
    <div class="model-body">
      <div class="modal-header">
        <h5 class="modal-title">Session Expired</h5>
      </div>
      <p class="promptMsg">{{promptmessage}}</p>
      <div class="buttonclass"><button class="buttonprimary" (click)="closepopup()">OK</button>&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  </div>
  <div class="model-background"></div>
</div>