import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorService } from '../services/operator.service';

@Component({
  selector: 'app-login',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  errorTitle: string = 'Oops! Something Went Wrong'; 
  errorDecription: string = 'We\'re sorry, but an unexpected error occurred. Please try again later.';
  hideBtn: boolean = false; 

  constructor(private _router: Router, private os: OperatorService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route?.queryParams?.subscribe(params => {
      console.log(params?.errorTitle)
      if(params?.errorTitle) {
        this.errorTitle = params?.errorTitle;
        this.errorDecription = params?.errorDecription;
        this.hideBtn = params?.hideBtn;
      }
    });
  }


  closepopup() {
    localStorage.clear();
    window.location.href = this.os.getDslMonitoringUrl();
  }
}
