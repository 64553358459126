import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class DataUploadService {

  //private apiUrl = `https://lvx98mb8hj.execute-api.ap-southeast-1.amazonaws.com/dev/cubs-dsl-device-dev-fileupload`;
    // private apiUrl = `https://cubs-uat-api.dslmonitoring.net/file-upload/fileupload`;
  private apiUrl = `https://cubs-api.dslmonitoring.net/file-upload/fileupload`;

  httpOptions = {
    headers: new HttpHeaders({
      //'Content-Type': 'multipart/form-data',
      'Content-Type': 'application/json',
    })
  };
  //headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient, private cookieService: CookieService
  ) {
  }

  fileUpload(formData: any) {
    let API_URL = `${this.apiUrl}`;
       // return this.http.post(`${this.apiUrl}`, formData)
    return this.http.post(API_URL, formData, { headers: { 'X-CSRF-TOKEN': this.cookieService.get('csrtoken') }, withCredentials: true })
      .pipe(
        catchError(this.error)
      );
  }


  // fileUpload(formData: any) {
  //   return this.http.post<any>(this.apiUrl, formData);
  // }

  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  // private fileUploadProgress(event: any) {
  //   const percentDone = Math.round(100 * event.loaded / event.total);
  //   return { progress: percentDone, files: [] };
  // }
}
