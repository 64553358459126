<!-- <head>
    <script src="https://unpkg.com/amazon-quicksight-embedding-sdk@1.20.0/dist/quicksight-embedding-js-sdk.min.js"></script>
</head> -->

<div class="container-fluid">
    <div class="row topspace1">
        <div class="col-12">
            <h5>QuickSight</h5>
            <div id="dashboardContainer"></div>
        </div>
    </div>
</div>